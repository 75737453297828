import React, { Component } from 'react';
import { isMobileDevice } from "../../helper/isMobileDevice";
import classnames from 'classnames';
//assets
import facebook_desktop from '../../../static/images/integrations/facebook_desktop.svg';
import facebook_mobile from '../../../static/images/integrations/facebook_mobile.svg';
import facebook_logo from '../../../static/images/integrations/facebook_logo.svg';


export default class Facebook extends Component {

    state = {
        is_mobile: false
    }

    componentDidMount(){
        if (isMobileDevice()) {
            this.setState({
                is_mobile: true
            })
        }
    }
    openMetaWorkspace = () => {
        // To-Do Later: Replace with correct URL
        // window.open(`https://login.microsoftonline.com/common/adminconsent?client_id=c6eefc55-594e-461e-9016-c70661a0e943&state=12345&redirect_uri=${config.url}app_integrations/ms_teams/consent`)
    }

    openMetaWorkspaceVideo = () => {

    }
    render() {
        const {is_mobile} = this.state;
        return (
            <div className={classnames('columns flex-wrap no-margin-left integrations', {'is-fullwidth': is_mobile})}>
                <div className="column is-12 columns no-padding-integrations no-margin-left center-align-items">
                    <div className="column is-5 columns flex-wrap no-margin-left no-padding-left">
                        <div className={classnames("is-12 columns column flex-wrap mg-top-n-30", {'pd-left-70': !is_mobile}, {'has-text-centered no-padding-left': is_mobile})}>
                            <p className={classnames("has-text-weight-bold line-height-2 mg-bottom-20 column is-12 mg-bottom-10 integrations-heading-color", {'font-40 is-pulled-left': !is_mobile}, {'title is-3': is_mobile})}>
                            Seamless Integration with Workplace - your everyday tool!
                            </p>
                            <p className={classnames("is-pulled-left column is-12 no-padding-top no-padding-bottom mg-bottom-10 integrations-sub-text-color", {'font-18': !is_mobile}, {'font-12 mg-left-right-10 mg-bottom-20': is_mobile})}>
                            With our Workplace integration, Amber brings employee engagement & wellbeing directly into the natural flow of people’s work. No more missing out. No more low response rates.
                            </p>
                        </div>
                        <div className={classnames("is-11 column columns", {'flex-wrap no-padding no-margin has-text-centered': is_mobile}, {'pd-left-70 has-text-left': !is_mobile})}>
                            <button disabled className={classnames('button has-text-weight-bold meta-button-color', {'column is-2 width-260 mg-left-15': !is_mobile, 'is-inline-block width-260 mg-top-10': is_mobile})} onClick={this.openMetaWorkspace}>
                                <span className='icon'>
                                    <img className="mg-right-10 mg-top-10" width="20" height="20" src={facebook_logo} alt="Bottom"/>
                                    <span className='mg-top-10'>
                                        Add to Workplace
                                    </span>
                                </span>
                            </button>
                            <button disabled className={classnames("button has-text-weight-bold", {'mg-left-10 column is-2 width-170': !is_mobile}, {'mg-top-10 is-inline-block width-260 mg-bottom-20': is_mobile})} onClick={this.openMetaWorkspaceVideo}>
                                Watch Demo
                            </button>
                        </div>
                    </div>
                    <div className="column is-7 no-padding-integrations">
                        <img className={classnames("mg-bottom-30", {'is-pulled-right': !is_mobile}, {'is-pulled-left': is_mobile})} src={is_mobile ? facebook_mobile : facebook_desktop} alt="Slack"/>
                    </div>
                </div>
            </div>
        )
    }
}
